import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";

import {
  NNavbarDashboard,
  ScrollToTopOnMount,
  NStepContainer,
} from "components/Nimbiv2";
import {
  PageSecond,
  PageThird,
  PageFourth,
  PageFifth,
} from "containers/Initiatives/pages";
import {
  validateAddInfo,
  validatePageTemplate,
  validateSelectStudent,
  validateStepFile,
} from "containers/Initiatives/pages/validations";
import { fetchClientFilters } from "store/actions/async/site-async.action";
import {
  APP_MODULES,
  deleteLocalStorage,
  getGlobalConfig,
  setSessionFilters,
  storeClientAllFilters,
} from "utils";
import {
  cleanFilter,
  loadClientFilters,
  setActiveModule,
  updateClientFilters,
} from "store/actions/site.actions";
import {
  fetchCoursesFromCampus,
  fetchInitiative,
  fetchListStudentTracking,
} from "store/actions/async/initiatives-async.actions";
import { getCampusesID, getTemplateID } from "utils/initiatives";
import PageFourthFile from "containers/Initiatives/pages/PageFourthFile";
import { cleanStudentTrackingInitiative } from "store/actions/initiative.actions";
import RouteLeavingGuard from "components/Nimbiv2/RouteLeavingGuard";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const panel = APP_MODULES.INITIATIVES.ID;
const positionsForm = {
  edit: ["addInfo", "selectTemplate", "selectStudent", "resume"],
  editExternal: ["addInfo", "selectTemplate", "resume"],
};
const descriptionStep = {
  selectInitiative: "Seleccionar como empezar campaña",
  selectType: "Seleccionar tipo de campaña",
  addInfo: "Completar<br/> información",
  selectTemplate: "Elegir plantilla",
  selectStudent: "Seleccionar alumnos<br/> y definir criterio",
  resume: "Revisar información<br/> y confirmar",
  addFile: "Subir archivo",
  selectClone: "Seleccionar campaña a clonar",
};

const EditInitiative = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [position, setPosition] = useState(0);
  const { id } = useParams();
  const { initiative } = useSelector((state) => state.initiative);
  const { templates, campus: listCampus } = useSelector((state) => state.crm);
  const { clientFilters } = useSelector((state) => state.site);
  const [typeForm, setTypeForm] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [errors, setErrors] = useState({});
  const [bannerBlocked, setBannerBlockeed] = useState(false);
  const history = useHistory();
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (form.source_type === "external") {
      setTypeForm(positionsForm["editExternal"]);
    } else {
      setTypeForm(positionsForm["edit"]);
    }
  }, [form.source_type]);

  const validate_campus_selected = (campos_selected) => {
    if (campos_selected.length === 0) {
      return false;
    }
    for (let i = 0; i < campos_selected.length; i++) {
      if (campos_selected[i] === null) {
        return false;
      }
    }
    return true;
  };

  const tracking_object_initial = async () => {
    if (
      initiative.tracking_objects &&
      form.campos_selected &&
      validate_campus_selected(form.campos_selected)
    ) {
      const campusCourses = await fetchCoursesFromCampus(
        form.campos_selected[0].campus
      );

      const tracking_object_selected = campusCourses.filter((item) =>
        initiative.tracking_objects
          .map((itemTrackingObject) => {
            return itemTrackingObject.id;
          })
          .includes(item.id)
      );
      const { student_trackings = [] } = await fetchListStudentTracking(id);
      onChangeForm(
        "tracking_objects",
        initiative.tracking_objects.map((item) => {
          return item.id;
        }),
        {
          tracking_object_selected: tracking_object_selected,
          search: true,
          student_trackings: student_trackings,
        }
      );
    }
  };
  const student_tracking_all = async () => {
    if (form.campos_selected && form.campos_selected.length > 1) {
      const { student_trackings = [] } = await fetchListStudentTracking(id);
      onChangeForm("search", true, {
        student_trackings: student_trackings,
      });
    }
  };

  useEffect(() => {
    if (initiative) {
      if (
        initiative.tracking_objects &&
        !form.tracking_object_selected &&
        !form.search
      ) {
        tracking_object_initial();
      } else {
        if (!form.search) {
          student_tracking_all();
        }
      }
    }
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      initiative &&
      templates &&
      listCampus &&
      listCampus.length > 0 &&
      clientFilters
    ) {
      deleteLocalStorage("initiative_filters");
      let template = {};
      if (initiative.initiative_type.name === "Email") {
        const x = getTemplateID(
          initiative.email_template.id,
          templates["email"]
        );
        if (x) {
          template = {
            email_template: initiative.email_template.id.toString(),
            template: x,
            name_template: x.name,
          };
        }
      }
      if (initiative.initiative_type.name === "Call") {
        const x = getTemplateID(
          initiative?.script_template?.id,
          templates["call"]
        );
        if (x) {
          template = {
            end_date: initiative.end_date
              ? moment(initiative.end_date).toDate()
              : null,
            script_template: initiative.script_template.id.toString(),
            template: x,
            name_template: x.name,
          };
        }
      }
      const new_campuses = initiative.campuses.map((item) => {
        return item.id;
      });
      const client_filters = initiative.client_filters.map((item) => {
        return item.id;
      });
      let campos_selected;
      if (initiative.campuses.length === 1 && listCampus) {
        const select = getCampusesID(initiative.campuses[0].id, listCampus);
        if (select) {
          campos_selected = { campos_selected: [select] };
        }
      } else {
        if (initiative.campuses.length === listCampus.length) {
          campos_selected = {
            campos_selected: [{ campus: "Todos", id: -1, campus_id: -1 }],
          };
        } else {
          let selectx = [];
          for (let i = 0; i < initiative.campuses.length; i++) {
            const select = getCampusesID(initiative.campuses[i].id, listCampus);
            selectx.push(select);
          }
          campos_selected = { campos_selected: selectx };
        }
      }

      setSessionFilters(APP_MODULES.INITIATIVES.FILTER_NAME, null);
      storeClientAllFilters(
        initiative.clientfilter_values,
        APP_MODULES.INITIATIVES.FILTER_NAME
      );
      let tempFilters = {
        data: clientFilters.data,
        filters: {
          ...clientFilters.filters,
          ...{ [panel]: initiative.clientfilter_values },
        },
      };

      let derive = {};
      if (initiative.enter_action_plans) {
        derive["enter_action_plans"] = initiative.enter_action_plans;
      }
      if (initiative.moments) {
        derive["moments_selected"] = initiative.moments;
      }

      let file = {};
      if (initiative.source_type === "external") {
        file["file_url"] = initiative.base_file;
      }
      let end_date_dict = {};
      if (initiative.initiative_type.name === "Call") {
        end_date_dict = {
          end_date: initiative.end_date
            ? moment(initiative.end_date).toDate()
            : null,
        };
        if (initiative?.script_template?.created_in_initiative) {
          end_date_dict["created_in_initiative"] = true;
          end_date_dict["script_template_name"] =
            initiative?.script_template?.name;
        }
      }
      dispatch(updateClientFilters(tempFilters));

      let formJson = {
        selectAll: false, //-----
        initiative_type: initiative.initiative_type.id,
        campuses: new_campuses,
        name: initiative.name,
        objective: initiative.objective,
        optionInitiative: initiative.initiative_type.name,
        optionCreate: "new",
        clientfilter_values: initiative.clientfilter_values,
        client_filters: client_filters,
        client_area: initiative.client_area,
        source_type: initiative.source_type ?? "internal",
        team: initiative.team?.id,
        start_date: initiative.start_date
          ? moment(initiative.start_date).toDate()
          : null,
        ...template,
        ...campos_selected,
        ...derive,
        search: false,
        ...file,
        ...end_date_dict,
      };
      if (getGlobalConfig("enable_send_all_contacts", false)) {
        formJson["send_all_contacts"] = initiative?.send_all_contacts;
      }
      setForm(formJson);
      //  tracking_object_initial();
    }
  }, [initiative, templates, listCampus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(loadClientFilters());
    dispatch(cleanStudentTrackingInitiative());
    dispatch(cleanFilter("initiative"));
    dispatch(fetchClientFilters({ module: APP_MODULES.INITIATIVES.ID }));

    dispatch(
      setActiveModule({
        title: "Editar campaña",
        subtitle: null,
        breadcrumbles: [
          {
            title: "Campañas",
            url: "/initiatives",
          },
          {
            title: "Editar campaña",
            url: null,
          },
        ],
      })
    );
    dispatch(fetchInitiative(id));
  }, [dispatch, id]);

  const onChangeForm = (
    key,
    value,
    data,
    next = false,
    reemplaceAll = false,
    initial = false,
    deleteKeys
  ) => {
    if (key && value) {
      if (key.includes("template")) {
        let errorsRemove = errors;
        delete errorsRemove["required_select_template"];
        setErrors(errorsRemove);
      } else {
        let errorsRemove = errors;
        delete errorsRemove[key];
        setErrors(errorsRemove);
      }
    }

    if (count > 0) {
      setBannerBlockeed(true);
    } else {
      setCount((count) => count + 1);
    }

    if (data) {
      let newFormData = { ...form, [key]: value, ...data };
      if (deleteKeys) {
        for (let keys in deleteKeys) {
          delete newFormData[deleteKeys[keys]];
        }
      }
      setForm(newFormData);
    } else {
      setForm({ ...form, [key]: value });
    }
  };

  const isEmpty = (item) => {
    return Object.keys(item).length === 0;
  };

  const validationFunctions = {
    addFile: validateStepFile,
    addInfo: validateAddInfo,
    selectTemplate: validatePageTemplate,
    selectStudent: validateSelectStudent,
  };

  const nextValidate = useCallback(
    (position, new_position = null) => {
      setErrors({});
      let posText = typeForm[position];
      if (!(new_position !== null && new_position < position)) {
        if (validationFunctions.hasOwnProperty(posText)) {
          let fun = validationFunctions[posText];
          let value = fun(form);
          if (!isEmpty(value)) {
            setErrors(value);
            return;
          }
        }
      }

      if (new_position !== null) {
        setPosition(new_position);
      } else {
        setPosition(position + 1);
      }
    },
    [typeForm, form, validationFunctions]
  );

  const onChangePosition = (value) => {
    if (value === "next") nextValidate(position);
    if (value === "last") setPosition(position - 1);
  };

  const validateAll = () => {
    let warning = typeForm.filter((item) => {
      if (validationFunctions[item]) {
        let fun = validationFunctions[item];
        let value = fun(form);
        return !isEmpty(value);
      }
      return false;
    });
    setWarnings(warning);
    return warning.length > 0;
  };

  const changeNavigate = useCallback(
    (path) => {
      history.push(path);
    },
    [history]
  );

  return (
    <div>
      <RouteLeavingGuard
        title={t("cofirm_abandon_initiative_edit")}
        detail={t("detail_discard_initiative")}
        when={bannerBlocked}
        navigate={changeNavigate}
        textCancel={_.upperFirst(t("not"))}
        textAccept={_.upperFirst(t("yes"))}
      />
      <ScrollToTopOnMount />
      <NNavbarDashboard breadcrumbles={true}></NNavbarDashboard>
      <Container style={{ marginTop: "24px" }}>
        <NStepContainer
          items={typeForm}
          position={position}
          activeSteps={typeForm}
          onChangeStep={(new_position, item) => {
            nextValidate(position, new_position);
          }}
          descriptions={descriptionStep}
          warnings={warnings}
        ></NStepContainer>
      </Container>
      <Container style={{ marginTop: "24px" }}>
        {typeForm[position] === "addInfo" && (
          <PageSecond
            pageNumber={"Paso 1"}
            onChange={onChangePosition}
            onChangeForm={onChangeForm}
            formValues={form}
            course={initiative ? initiative.tracking_objects : null}
            edit={true}
            errors={errors}
          ></PageSecond>
        )}
        {typeForm[position] === "selectTemplate" && (
          <PageThird
            pageNumber={"Paso 2"}
            onChange={onChangePosition}
            onChangeForm={onChangeForm}
            formValues={form}
            errors={errors}
            edit={true}
          ></PageThird>
        )}
        {typeForm[position] === "selectStudent" &&
          form.source_type === "internal" && (
            <PageFourth
              pageNumber={"Paso 3"}
              onChange={onChangePosition}
              onChangeForm={onChangeForm}
              formValues={form}
              edit={true}
              errors={errors}
            ></PageFourth>
          )}
        {typeForm[position] === "selectStudent" &&
          form.source_type === "external" && (
            <PageFourthFile
              onChange={onChangePosition}
              onChangeForm={onChangeForm}
              formValues={form}
              errors={errors}
            ></PageFourthFile>
          )}
        {typeForm[position] === "resume" && (
          <PageFifth
            edit={true}
            pageNumber={"Paso 4"}
            onChange={onChangePosition}
            setPosition={(position) => {
              setPosition(position);
            }}
            formValues={form}
            id={id}
            validateAll={validateAll}
            sucesssSave={(state) => {
              setBannerBlockeed(false);
            }}
          ></PageFifth>
        )}
      </Container>
    </div>
  );
};
export default EditInitiative;
